<template>
  <div :class="is_study_information != 1 ? 'Investment_research' : ''">
    <div class="img_title" v-if="is_study_information != 1">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">品种资讯</span>
      </div>
      <img style="width: 100%; height: 100%" src="../../assets/img/title_1.jpg" alt="" />
    </div>
    <div class="data_centre">
      <div class="search_box ">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="交易所" style="margin-right: 15px">
            <el-select v-model="exchange" placeholder="交易所" size="mini" style="width: 200px" @change="handle_exchange">
              <el-option v-for="item in exchanges" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-right: 15px">
            <el-select v-model="varietie" placeholder="品种" size="mini" style="width: 200px"
              @change="handle_varietiechange">
              <el-option v-for="item in varieties" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期" style="margin-right: 15px">
            <el-date-picker :picker-options="pickerOptions" @change="timechange" v-model="time" type="daterange"
              format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div class="bg-color">
              <i class="el-icon-warning"></i><span @click="dialogVisible = true">免责声明</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div
        style="margin: 0 auto; width: 1200px;display: flex;flex-direction: row;justify-content: space-between;align-items: flex-start;">
        <div class="bg-color2">
          <div class="real-time-data">
            <ul id="real-time-data" class="list-inline">
              <li><strong>最新数据</strong></li>
              <li>现货价格：<label>{{ lastValue ? lastValue.sclosePrices : '' }}</label></li>
              <li>期货价格：<label>{{ lastValue ? lastValue.fclosePrices : '' }}</label></li>
              <li>基差：<label>{{ lastValue ? lastValue.basis : '' }}</label></li>
              <li>平均基差：<label>{{ pageData.avgBasis }}</label></li>
              <li>主力合约：<label>{{ lastValue ? lastValue.contract : '' }}</label></li>
            </ul>
          </div>
          <div ref="echar_1" class="echar_box_1"></div>
          <div ref="echar_2" class="echar_box_2"></div>
        </div>
        <div class="bg-color5" id="baseInfo">
          <h4><i class="fa fa-list-alt"></i>基础信息</h4>
          <p><strong>现货：</strong>{{ pageData.name }}</p>
          <p><strong>期货合约：</strong>{{ lastValue ? lastValue.contract : '' }}</p>
          <p><strong>交易单位：</strong>{{ pageData.tradingUnit }}</p>
          <p><strong>报价单位：</strong>{{ pageData.contractSize }}</p>
          <p><strong>最后交易日：</strong>{{ lastValue ? lastValue.tradingDay : '' }}</p>
          <p><strong>最低交易保证金：</strong>{{ pageData.margin }}</p>
          <p><strong>交易时间：</strong>{{ pageData.tradingTime }}</p>
        </div>
      </div>

    </div>
    <el-dialog :visible.sync="dialogVisible" width="420px">
      <h2 style="font-size: 30px; line-height: 50px; text-align: center">
        免责声明
      </h2>
      <p style="text-indent: 2em; line-height: 30px">
        除非另有说明，本数据分析系统的著作权属恒银期货有限公司。未经恒银期货有限公司书面授权，任何人不得更改或以任何方式发送、复制或传播此数据分析系统的全部或部分材料、内容。除非另有说明，此数据分析系统中使用的所有商标、服务标记及标记均为恒银期货有限公司的商标、服务标记及标记。恒银期货有限公司不会故意或有针对性地将本系统提供给任何有限制或有可能导致恒银期货有限公司违法的国家、地区或其它法律管辖区域。
      </p>
      <p style="text-indent: 2em; line-height: 30px">
        恒银期货有限公司对本数据分析系统的服务及其内容的准确性、完整性不做任何形式的担保。由于互联网以及卫星通讯方面的各个因素，恒银期货有限公司不担保服务的可靠性，对服务的及时性、安全性、出错发生均不作担保。此数据分析系统所载的全部内容仅作参考之用，不构成对任何人的投资建议，且恒银期货有限公司不因接收人收到此数据分析的内容而视其为客户。
      </p>
      <p style="text-indent: 2em; line-height: 30px">
        此数据分析系统服务可能不适合阁下，我们建议阁下如有任何疑问应咨询独立投资顾问。此数据分析系统服务不构成投资、法律、会计或税务建议，且不担保任何投资及策略适合阁下。此数据分析系统服务并不构成给予阁下的私人咨询建议。
      </p>
      <p style="text-indent: 2em; line-height: 30px">
        恒银期货有限公司版权所有并保留一切权利。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from 'moment'
export default {
  name: 'Investment_research',
  props: ['is_study_information'],
  data() {
    return {
      exchanges: [],
      varieties: [],
      varietie: '',
      exchange: '',
      time: [moment().subtract(3, 'year').format('yyyy-MM-DD'), moment().format('yyyy-MM-DD')],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogVisible: false,
      formInline: {
        time: "",
        variety: "",
        contract: "",
        transaction: "",
      },
      pageData: {},
      lastValue: {}
    };
  },
  async mounted() {
    await this.getData();
    await this.handle_echar1();
    await this.handle_echar2();
    // this.handle_echar3();
  },
  methods: {
    timechange() {
      this.handle_varietiechange(this.pageData.id)
    },
    handle_varietiechange(data) {
      //   获取页面所有基本信息
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/variety_values`,
        params: {
          varietyId: data,
          startDate: this.time[0],
          endDate: this.time[1]
        },
      }).then(res => {
        this.pageData = res.data.data;
        this.lastValue = this.pageData.lastValue;
        this.handle_echar1();
        this.handle_echar2();
      })
    },
    handle_exchange(data) {
      //     品种列表
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/varieties?exchangeId=${data}`,
      }).then((res) => {
        this.varieties = res.data.data;
        this.varietie = res.data.data[0].id;
        this.handle_varietiechange(res.data.data[0].id);
        this.handle_echar1();
        this.handle_echar2();
      });
    },
    async getData() {
      //     交易所列表
      let res = await this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/exchanges`,
      })
      this.exchanges = res.data.data;
      this.exchange = res.data.data[0].id;
      //     品种列表
      let res1 = await this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/varieties?exchangeId=${this.exchange}`,
      })
      this.varieties = res1.data.data;
      this.varietie = res1.data.data[0].id;
      //   获取页面所有基本信息
      let res2 = await this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/variety_values`,
        params: {
          varietyId: this.varietie,
          startDate: this.time[0],
          endDate: this.time[1]
        },
      });
      this.pageData = res2.data.data;
    },
    async handle_echar2() {
      var chartDom = this.$refs["echar_2"];
      var myChart = echarts.init(chartDom);
      var option;
      var basis = this.pageData.values.map((item) => item.basis);
      var tradingDay = this.pageData.values.map((item) => item.tradingDay);
      var basisAverageArr = [];
      basis.forEach((item) => {
        basisAverageArr.push(this.pageData.avgBasis);
      });
      var option = {
        title: {
          text: "历史基差",
          subtext: "恒银期货",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["基差", "平均基差"],
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: tradingDay,
        },
        yAxis: {
          name: "基差",
          type: "value",
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: "{value}",
          },
          min: function (value) {
            return value.min;
          },
          max: function (value) {
            return value.max;
          },
        },
        series: [
          {
            name: "基差",
            type: "line",
            color: "#c23531",
            symbol: "none",
            smooth: true,
            data: basis,
          },
          {
            name: "平均基差",
            type: "line",
            color: "#2f4554",
            symbol: "none",
            smooth: true,
            data: basisAverageArr,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    formatNumber(num, unit) {
      if (isNaN(unit)) {
        unit = 2;
      }
      return isNaN(num) ? 0 : Math.floor(num * Math.pow(10, unit)) / Math.pow(10, unit);
    },
    handle_echar1() {
      var chartDom = this.$refs["echar_1"];
      var myChart = echarts.init(chartDom);
      var option;
      var tradingDay = this.pageData.values.map((item) => item.tradingDay);
      // var fclosePrices = this.pageData.values.map((item) => ({ value: item.openPrice, groupId: item.id }));
      // var sclosePrices = this.pageData.values.map((item) => ({ value: item.closePrice, groupId: item.id }));

      var fclosePrices = this.pageData.values.map((item) => ({ value: item.fclosePrices, groupId: item.id }));
      var sclosePrices = this.pageData.values.map((item) => ({ value: item.sclosePrices, groupId: item.id }));
      var option = {
        title: {
          text: "历史价格",
          subtext: "恒银期货",
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
          },
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderWidth: 1,
          borderColor: "#ccc",
          padding: 10,
          textStyle: {
            color: "#000",
          },
          position: function (pos, params, el, elRect, size) {
            var obj = { top: 20, right: 10 };
            obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 150;
            return obj;
          },
          extraCssText: "width: 170px",
          formatter: (params, ticket, callback) => {
            var tipText = '';
            if (this.pageData.asset != 'T' && this.pageData.asset != 'TF') {
              if (params.seriesIndex == 0) {
                this.$axios({
                  method: "get",
                  headers: {
                    'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
                  },
                  url: `/api/web/tx/variety_value`,
                  params: {
                    id: params.data.groupId,
                  },
                }).then(res => {
                  let result = res.data;
                  if (result['success']) {
                    var data = result['data'];
                    if (data) {
                      tipText =
                        '日期：' +
                        params.name +
                        '<br/>开盘价：' +
                        data['openPrice'] +
                        '<br/>最高价：' +
                        data['highestPrice'] +
                        '<br/>最低价：' +
                        data['lowestPrice'] +
                        '<br/>收盘价：' +
                        data['closePrice'] +
                        '<br/>结算价：' +
                        data['settlementPrice'] +
                        '<br/>成交量：' +
                        data['volume'] +
                        '<br/>持仓量：' +
                        data['openInterest'] +
                        '<br/>涨跌：' +
                        data['change'] +
                        '<br/>涨跌幅：' +
                        data['changeRate'];
                    }
                    callback(ticket, tipText);
                  }
                });
              } else {
                this.$axios({
                  method: "get",
                  headers: {
                    'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
                  },
                  url: `/api/web/tx/variety_value`,
                  params: {
                    id: params.data.groupId,
                  },
                }).then(res => {
                  let result = res.data;
                  if (result['success']) {
                    var data = result['data'];
                    if (data) {
                      tipText =
                        '日期：' +
                        params.name +
                        '<br/>收盘价：' +
                        data['closePrice'];
                    }
                    callback(ticket, tipText);
                  }
                }
                );
              }
            } else {
              this.$axios({
                method: "get",
                headers: {
                  'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
                },
                url: `/api/web/tx/variety_value`,
                params: {
                  id: params.data.groupId,
                },
              }).then(res => {
                let result = res.data;
                if (result['success']) {
                  var data = result['data'];
                  if (data) {
                    if (params.seriesIndex == 0) {
                      tipText =
                        '日期：' +
                        params.name +
                        '<br/>CTD代码：' +
                        data['tbfCtd'] +
                        '<br/>期货价格：' +
                        data['close'] +
                        '<br/>交易代码：' +
                        data['tradingHiscode'] +
                        '<br/>最后交易日：' +
                        moment(data['lastTradingDay']).format('YYYY-MM-DD');
                    } else {
                      tipText =
                        '日期：' +
                        params.name +
                        '<br/>CTD代码：' +
                        data['tbfCtd'] +
                        '<br/>现货价格：' +
                        data['netCsi'] / data['tbfCvf'];
                    }
                  }
                  callback(ticket, tipText);
                }
              }
              );
            }
            // setTimeout(function () {
            // }, 100);
            return 'loading';
          }
        },
        legend: {
          data: ["现货", "期货"],
        },
        axisPointer: {
          link: { xAxisIndex: "all" },
          label: {
            backgroundColor: "#777",
          },
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none",
            // },
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: tradingDay,
          axisPointer: {
            label: {
              formatter: function (params) {
                var seriesValue = (params.seriesData[0] || {}).value;
                return (
                  params.value +
                  (seriesValue != null
                    ? "\n" + echarts.format.addCommas(seriesValue)
                    : "")
                );
              },
            },
          },
        },
        yAxis: {
          name: "价格",
          type: "value",
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: "{value}",
          },
          min: function (value) {
            return value.min;
          },
          max: function (value) {
            return value.max;
          },
        },
        series: [
          {
            name: "期货",
            color: "#c23531",
            type: "line",
            showSymbol: true,
            // symbol: 'circle',     //设定为实心点
            symbolSize: 8,   //设定实心点的大小
            itemStyle: {
              normal: {
                borderColor: null,
                borderColor0: null,
              },
            },
            data: fclosePrices,
          },
          {
            name: "现货",
            showSymbol: true,
            symbolSize: 8,   //设定实心点的大小
            type: "line",
            color: "#2f4554",
            data: sclosePrices,
          },
        ],
      };
      myChart.setOption(option);
      option && myChart.setOption(option);
    },
  },
};
</script>
<style scoped>
.bg-color2 {
  background: #fff;
  min-height: 758px;
  height: auto;
  padding: 20px;
  margin-right: 20px;
  flex: 1;
}

.bg-color3 {
  margin-top: 20px;
}

.real-time-data ul li {
  padding: 0 10px;
  border-right: 1px solid #ddd;
}

.real-time-data {
  margin: 30px 0px;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

ol,
ul,
li,
li {
  list-style: none;
}

b,
strong {
  font-weight: bold;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.search_box {
  width: 1200px;
  margin: 30px auto 20px auto;
  height: 130px;
  padding: 45px 30px;
  background: #fff;
}

.echar_box_b {
  width: 100%;
  border: 1px solid #e2e2e2;
  margin-top: 10px;
}

.echar_box {
  width: 1200px;
  margin: 0 auto;
}

.echar_box_1 {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

.echar_box_2 {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

.echar_box_3 {
  width: 100%;
  height: 400px;
  border: 1px solid #e2e2e2;
  margin-top: 20px;
}

.article_details {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 38px;
  padding: 30px 0;
}

/* .article_details img{
  width: 100%;
} */
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.img_title {
  width: 100%;
  background: #ccc;
}

.Investment_research {
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: -75px;
  padding-bottom: 75px;
}

* {
  box-sizing: border-box;
}

.badge-red {
  color: #fff;
  background: #dc1f1f;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 0;
  display: inline-block;
  border-radius: 50%;
}

.badge {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: #999;
  background: #eee;
  border-radius: 50%;
}

.bg-color span {
  color: #064b9c;
  cursor: pointer;
}

.bg-color span:hover {
  color: #c00;
  text-decoration: underline;
}

.bg-color i {
  color: #337ab7;
  margin-right: 7px;
}

.bg-color5 {
  background: #fff;
  height: auto;
  width: 370px;
  padding: 5px 15px 10px 15px;
}

.bg-color5 ul li {
  color: #c8c8c8;
  line-height: 40px;
  /* background: url(../images/dian_05.png) no-repeat 0px 18px; */
  padding-left: 15px;
}

.bg-color5 ul {
  text-align: right;
  font-size: 12px;
  padding: 0;
}

.bg-color5 i {
  margin-right: 10px;
}

.bg-color5 a {
  color: #666;
  text-decoration: none;
  font-size: 13px;
  float: left;
}

.bg-color5 a:visited {
  color: #666;
}

.bg-color5 a:hover {
  color: #e60e0e;
  text-decoration: underline;
}

.bg-color5 h4 {
  font-size: 16px;
  color: #064b9c;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}

.bg-color5 p {
  font-size: 13px;
  color: #666;
  line-height: 30px;
}

.bg-color5 strong {
  color: #333;
}
</style>
